import getProfileService from "@/services/auth";
import {sentryCapture, sentryErrFormat} from "@/utils/sentryLogger";
import useAuthStore from "@/zus/auth";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import useHydrated from "../useHydrated";
// import {useCookies} from "react-cookie";

const useAuthHooks = (isFetch: boolean) => {
  const router = useRouter();
  const {updateProfile, reset} = useAuthStore();
  const [loading, setLoading] = useState(true);
  const isHydrated = useHydrated();
  //   const [cookies, , removeCookie] = useCookies(["token"]);

  useEffect(() => {
    if (isFetch && isHydrated) {
      if (typeof window != undefined) {
        doGetTheProfile();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHydrated]);

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  const doGetTheProfile = async () => {
    if (window.localStorage.getItem("token")) {
      await doGetProfile();
      stopLoading();
    } else {
      await doLogout();
      stopLoading();
    }
  };

  const doLogout = async () => {
    const isDashboard = router.asPath.includes("/dashboard");
    if (isDashboard) {
      await reset();
      router.replace("/");
    }
  };

  const doGetProfile = async () => {
    try {
      const res = await getProfileService();
      if (res?.data?.statusCode === 200 && res?.data?.data?.phoneNumber) {
        const data = res?.data?.data;
        const isDashboard = router.asPath.includes("/dashboard");
        if (data?.customerId) {
          updateProfile({
            customerId: data.customerId,
            phoneNumber: data.phoneNumber,
            firstName: data.contact.firstName,
            lastName: data.contact.lastName,
            email: data.email,
            companyName: data.name,
            ktp: data.ktp,
            npwp: data.npwp,
          });
        }
        if (!isDashboard) {
          router.replace("/dashboard/home");
        }
        return;
      } else throw res;
    } catch (error) {
      sentryCapture("auth - profile - useAuth", sentryErrFormat(error), error);
      doLogout();
      return;
    }
  };
  return {
    loading,
    doGetProfile,
  };
};

export default useAuthHooks;
