export const GA_TRACKING_ID = 'G-V5TL8TCC9Q';

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
  try {
    if (typeof window !== 'undefined') {
      window.gtag("config", GA_TRACKING_ID, {
        page_location: url,
      });
    }
  } catch (error) {
    //
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  try {
    if (typeof window !== 'undefined') {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      });
    }
  } catch (error) {
    //
  }
};
