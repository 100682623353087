import useHydrated from "@/hooks/useHydrated";
import {create} from "zustand";
import {persist} from "zustand/middleware";

interface userInterface {
  phoneNumber: string;
  accountType: number;
  accountTypeText: string;
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  ktp: string;
  npwp: string;
}

interface SigninUpInterface {
  sendCodeTimes: number;
  phoneNumber: string;
  targetResendCodeAt: number;
  accountType: number;
  register: (phoneNumber: string) => void;
  resendCode: () => void;
  reset: () => void;
  selectAccountType: (accountType: number) => void;
  tempUser?: userInterface;
  saveTempUser: (data: userInterface) => void;
}

export const useSignUp = create(
  persist<SigninUpInterface>(
    (set) => ({
      sendCodeTimes: 0,
      phoneNumber: "",
      targetResendCodeAt: 0,
      accountType: 0,
      register: (phoneNumber: string) => {
        const MINUTES = 3 * 60 * 1000;
        const NOW_IN_MS = new Date().getTime();

        const target = NOW_IN_MS + MINUTES;
        set((state) => {
          return {
            ...state,
            sendCodeTimes: 1,
            phoneNumber: phoneNumber,
            targetResendCodeAt: target,
          };
        });
      },
      resendCode: () => {
        set((state) => {
          const MINUTES = 3 * 60 * 1000;
          const NOW_IN_MS = new Date().getTime();

          const target = NOW_IN_MS + MINUTES;
          return {
            ...state,
            sendCodeTimes: state.sendCodeTimes + 1,
            targetResendCodeAt: target,
          };
        });
      },
      reset: () => {
        set(() => {
          return {
            sendCodeTimes: 0,
            phoneNumber: "",
            targetResendCodeAt: 0,
          };
        });
      },
      selectAccountType: (accountType: number) => {
        set((state) => {
          return {
            ...state,
            accountType: accountType,
          };
        });
      },
      saveTempUser: (data: userInterface) => {
        set((state) => {
          return {
            ...state,
            tempUser: data,
          };
        });
      },
    }),
    {name: "signup"},
  ),
);

const useSignUpStore: () => SigninUpInterface = () => {
  const store = useSignUp();
  const isHydrated = useHydrated();
  return isHydrated
    ? store
    : {
        sendCodeTimes: 0,
        phoneNumber: "",
        targetResendCodeAt: 0,
        accountType: 0,
        register: () => null,
        resendCode: () => null,
        reset: () => null,
        selectAccountType: () => null,
        saveTempUser: () => null,
      };
};

export default useSignUpStore;
