import {ErrorResponseAPIType} from "@/types/auth";
import axios from "axios";

export const handleGetDetailErrorAPI = (
  error: unknown,
): ErrorResponseAPIType => {
  if (axios.isAxiosError(error) && error.response) {
    if (error.response?.data?.data as ErrorResponseAPIType) {
      const data = error.response?.data?.data;
      return data;
    }
  }
  return {
    error_code: "",
    detail_error: "",
  };
};

export const whitelistedError = ["OTP_NOT_MATCH", "ACCOUNT_NOT_EXISTED", "ACCOUNT_SUSPENDED", "ACCOUNT_ALREADY_REGISTERED"]
