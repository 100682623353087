import {useEffect, useState} from "react";
import {browserName, browserVersion} from "react-device-detect";

// nextjs support

// Chrome 64+
// Edge 79+
// Firefox 67+
// Opera 51+
// Safari 12+

const useBrowserDetect = () => {
  const [isBrowserNotSupport, setIsBrowserNotSupport] = useState(false);

  const closeWarningBrowserSupport = () => {
    setIsBrowserNotSupport(false);
  };
  const checkIsBrowserSupport = () => {
    const browserNameString = browserName.toLowerCase();
    const browserVersionNumber = parseFloat(browserVersion);
    if (browserNameString.includes("chrome") && browserVersionNumber >= 64) {
      return true;
    }
    if (browserNameString.includes("edge") &&browserVersionNumber >= 79) {
      return true;
    }
    if (browserNameString.includes("firefox") && browserVersionNumber >= 67) {
      return true;
    }
    if (browserNameString.includes("firefox") && browserVersionNumber >= 51) {
      return true;
    }
    if (browserNameString.includes("safari") && browserVersionNumber >= 12) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (checkIsBrowserSupport() === false) {
      setIsBrowserNotSupport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    browserName,
    browserVersion,
    isBrowserNotSupport,
    closeWarningBrowserSupport,
  };
};

export default useBrowserDetect;
