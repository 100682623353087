import useHydrated from "@/hooks/useHydrated";
import {create} from "zustand";
import {persist} from "zustand/middleware";
import useSignInStore from "./signin";
import useSignUpStore from "./signup";

interface AuthInterface {
  phoneNumber: string;
  accountType: number;
  accountTypeText: string;
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  ktp: string;
  npwp: string;
  updateProfile: (action: {
    customerId: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName?: string;
    ktp?: string;
    npwp?: string;
  }) => void;
  sign: (action: {
    customerId?: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName?: string;
    accountType: number;
    accountTypeText: string;
    ktp?: string;
    npwp?: string;
  }) => void;
  reset: () => void;
  sendCodeTimes: number;
  targetResendCodeAt: number;
  resendCode: () => void;
}

export const useAuth = create(
  persist<AuthInterface>(
    (set) => ({
      customerId: "",
      phoneNumber: "",
      accountType: 0,
      accountTypeText: "",
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      ktp: "",
      npwp: "",
      sendCodeTimes: 0,
      targetResendCodeAt: 0,
      resendCode: () => {
        set((state) => {
          const MINUTES = 3 * 60 * 1000;
          const NOW_IN_MS = new Date().getTime();

          const target = NOW_IN_MS + MINUTES;
          return {
            ...state,
            sendCodeTimes: state.sendCodeTimes + 1,
            targetResendCodeAt: target,
          };
        });
      },
      updateProfile: (action: {
        customerId: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        email: string;
        companyName?: string;
        ktp?: string;
        npwp?: string;
      }) => {
        set((state) => {
          return {
            ...state,
            customerId: action.customerId,
            email: action.email,
            companyName: action.companyName,
            firstName: action.firstName,
            lastName: action.lastName,
            phoneNumber: action.phoneNumber,
            ktp: action.ktp,
            npwp: action.npwp,
          };
        });
      },
      sign: (action: {
        customerId?: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        email: string;
        companyName?: string;
        accountType: number;
        accountTypeText: string;
        ktp?: string;
        npwp?: string;
      }) => {
        set((state) => {
          return {
            ...state,
            customerId: action.customerId,
            email: action.email,
            companyName: action.companyName,
            firstName: action.firstName,
            lastName: action.lastName,
            phoneNumber: action.phoneNumber,
            accountType: action.accountType,
            accountTypeText: action.accountTypeText,
            ktp: action.ktp,
            npwp: action.npwp,
          };
        });
      },
      reset: () => {
        localStorage.clear();
      },
    }),
    {name: "auth"},
  ),
);

const useAuthStore: () => AuthInterface = () => {
  const store = useAuth();
  const isHydrated = useHydrated();
  return isHydrated
    ? store
    : {
        customerId: "",
        phoneNumber: "",
        accountType: 0,
        accountTypeText: "PERSONAL",
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        ktp: "",
        npwp: "",
        updateProfile: () => null,
        sign: () => null,
        reset: () => null,
        sendCodeTimes: 0,
        targetResendCodeAt: 0,
        resendCode: () => null,
      };
};

export {useSignUpStore, useSignInStore};
export default useAuthStore;
