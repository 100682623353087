import "@/styles/style.scss";

// type
import type {AppProps} from "next/app";
import {useRouter} from "next/router";
//
import {ReactElement, useEffect} from "react";

// Components
import DefaultSeo from "@/components/Seo";
import useAuthHooks from "@/hooks/services/useAuth";
import useBrowserDetect from "@/hooks/useBrowserDetect";
import type {NextPageWithLayout} from "@/types/pages";
import {pageview} from "@/utils/gtag";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Link from "next/link";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CustomApp({Component, pageProps: {...pageProps}}: AppPropsWithLayout) {
  const router = useRouter();
  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (isProduction) pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [isProduction, router.events]);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
  const {isBrowserNotSupport, closeWarningBrowserSupport} = useBrowserDetect();
  const {loading} = useAuthHooks(true);
  

  return (
    <>
      {isBrowserNotSupport && router.asPath !== "/not-supported" && (
        <div
          className="alert alert-warning relative z-[30] shadow-lg flex rounded-none flex-row bg-[#fbbd23]"
          onClick={closeWarningBrowserSupport}
        >
          <div className="flex-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-sm">
              You are using not our{" "}
              <Link className="link" href="/not-supported">
                recommendations browser
              </Link>
              , some pages might not showed well for you
            </span>
          </div>
          <XMarkIcon className="cursor-pointer" width={16} />
        </div>
      )}
      <DefaultSeo />
      {loading ? (
        <div className="flex flex-col transition-colors duration-150">
          <div className="min-h-screen">
            <main className="flex flex-col px-4 py-6 sm:px-6 md:px-20 lg:px-40">
              <div className="mt-4 flex flex-col items-center justify-center min-h-[380px] md:min-h-[300px]">
                <svg
                  className="animate-spin h-5 w-5 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="mt-1">loading ...</p>
              </div>
            </main>
          </div>
        </div>
      ) : (
        getLayout(<Component {...pageProps} />)
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
export default CustomApp;
