import {apiV1} from "@/constants/service.config";
import {ProfileResponse} from "@/types/auth";
import {sentryCapture, sentryErrFormat} from "@/utils/sentryLogger";
import axios, {AxiosResponse} from "axios";
import client from "../client";

interface ProfileAxiosResponse extends AxiosResponse {
  data: ProfileResponse;
}

const getProfileService = async (): Promise<ProfileAxiosResponse | null> => {
  try {
    const res: AxiosResponse = await client<ProfileResponse>({
      url: `${apiV1}/auth/profile`,
      method: "get",
    });
    return res;
  } catch (error) {
    sentryCapture("auth - profile - getProfileService", sentryErrFormat(error), error);
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        return error?.response;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
};

export const updateProfileService = async (data : {
  corporateName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}): Promise<ProfileAxiosResponse | null> => {
  try {
    const res: AxiosResponse = await client<ProfileResponse>({
      url: `${apiV1}/auth/profile`,
      method: "put",
      data: data,
    });
    return res;
  } catch (error) {
    sentryCapture("auth - profile - updateProfileService", sentryErrFormat(error), error);
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        return error?.response;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
};

export default getProfileService;
