import useHydrated from "@/hooks/useHydrated";
import {create} from "zustand";
import {persist} from "zustand/middleware";

interface SigninInterface {
  sendCodeTimes: number;
  phoneNumber: string;
  targetResendCodeAt: number;
  accountType: number;
  login: (phoneNumber: string) => void;
  resendCode: () => void;
  reset: () => void;
  selectAccountType: (accountType: number) => void;
}

export const useSignIn = create(
  persist<SigninInterface>(
    (set) => ({
      sendCodeTimes: 0,
      phoneNumber: "",
      targetResendCodeAt: 0,
      accountType: 0,
      login: (phoneNumber: string) => {
        const MINUTES = 3 * 60 * 1000;
        const NOW_IN_MS = new Date().getTime();

        const target = NOW_IN_MS + MINUTES;
        set((state) => {
          return {
            ...state,
            sendCodeTimes: 1,
            phoneNumber: phoneNumber,
            targetResendCodeAt: target,
          };
        });
      },
      resendCode: () => {
        set((state) => {
          const MINUTES = 3 * 60 * 1000;
          const NOW_IN_MS = new Date().getTime();

          const target = NOW_IN_MS + MINUTES;
          return {
            ...state,
            sendCodeTimes: state.sendCodeTimes + 1,
            targetResendCodeAt: target,
          };
        });
      },
      reset: () => {
        set(() => {
          return {
            sendCodeTimes: 0,
            phoneNumber: "",
            targetResendCodeAt: 0,
          };
        });
      },
      selectAccountType: (accountType: number) => {
        set((state) => {
          return {
            ...state,
            accountType: accountType,
          };
        });
      },
    }),
    {name: "signin"},
  ),
);

const useSignInStore: () => SigninInterface = () => {
  const store = useSignIn();
  const isHydrated = useHydrated();
  return isHydrated
    ? store
    : {
        sendCodeTimes: 0,
        phoneNumber: "",
        targetResendCodeAt: 0,
        accountType: 0,
        login: () => null,
        resendCode: () => null,
        reset: () => null,
        selectAccountType: () => null,
      };
};

export default useSignInStore;
