import {sentryCapture, sentryErrFormat} from "@/utils/sentryLogger";
import axios, {AxiosError, AxiosResponse} from "axios";
import {handleGetDetailErrorAPI, whitelistedError} from "@/utils/errorHelper";
import {logOnDev} from "@/utils/logger";

const {NEXT_PUBLIC_API_SERVER_URL} = process.env;

const client = axios.create({
  baseURL: NEXT_PUBLIC_API_SERVER_URL,
});

client.interceptors.request.use(
  function (config) {
    config.baseURL = process.env.NEXT_PUBLIC_API_SERVER_URL;
    if (typeof window != undefined) {
      const token = window.localStorage.getItem("token");
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const {method, url} = response.config;
  const {status} = response;

  logOnDev(
    `🚀 [API REQUEST] ${method?.toUpperCase()} ${url}
    )} | Response ${status}`,
  );
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (whiteListErrorSentry(error) === false) {
    sentryCapture(
      `API exception - ${error?.config?.url}`,
      sentryErrFormat(error),
      error,
    );
  }
  return Promise.reject(error);
};

const whiteListErrorSentry = (error: AxiosError) => {
  const dataError = handleGetDetailErrorAPI(error);
  if (dataError?.error_code) {
    if (whitelistedError.includes(dataError?.error_code)) {
      return true;
    }
  }

  return false;
};

client.interceptors.response.use(onResponse, onResponseError);

export default client;
