import * as Sentry from "@sentry/nextjs";
import axios from "axios";

export const sentryErrFormat = (error: unknown) => {
  try {
    if (axios.isAxiosError(error)) {
      return {
        response_data: JSON.stringify(error.response?.data),
        body_data: JSON.stringify(error.config?.data),
        params_data: JSON.stringify(error.config?.params),
      };
    } else {
      return JSON.stringify(error);
    }
  } catch (error) {
    return JSON.stringify(error);
  }
};

export const sentryCapture = (tag: string, data: unknown, exception: unknown) => {
  try {            
    Sentry.withScope(scope => {
      scope.setTag("section", tag);
      scope.setExtra("data", data);
      Sentry.captureException(exception);
    });
  } catch (error) {
    // error
  }
};
